/**
 * Adds a new empty example to the promptObject if the last example is not already empty.
 * @param task The current promptObject
 * @returns A new promptObject with the updated examples array
 */
export const addTask = (task: {
  messages: Array<{role: string; content: string}>;
  examples: Array<{input: string; target: string}>;
}) => {
  const lastExample = task.examples[task.examples.length - 1];
  const lastExampleIsEmpty = lastExample && lastExample.input === '';

  if (!lastExampleIsEmpty) {
    return {
      ...task,
      examples: [
        ...task.examples,
        {input: '', target: ''}, // Add a new empty example
      ],
    };
  }
  return task; // Return the original promptObject if the last example is already empty
};

/**
 * Updates a specific example in the promptObject's examples array.
 * @param task The current promptObject
 * @param index The index of the example to update
 * @param example The new example data
 * @returns A new promptObject with the updated example
 */
export const updateTask = (
  task: {
    messages: Array<{role: string; content: string}>;
    examples: Array<{input: string; target: string}>;
  },
  index: number,
  example: {input: string; target: string},
) => {
  return {
    ...task,
    examples: [
      ...task.examples.slice(0, index),
      example, // Replace the example at the specified index
      ...task.examples.slice(index + 1),
    ],
  };
};
