import React, {useState} from 'react';

import DiffModal from './PromptTrainingDiffModal';

interface Completion {
  prediction: string;
  score: number;
  feedback?: string;
  example?: any;
}

interface TableItemProps {
  completion: Completion;
  index: number;
  example: any;
  setCompletion: any;
}

function TableItem({
  completion,
  index,
  example,
  setCompletion,
}: TableItemProps) {
  return (
    <tr
      className="border-b bg-white text-xs dark:border-gray-700 dark:bg-gray-800"
      onClick={() => setCompletion({...completion, example})}
    >
      <th
        scope="row"
        className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
      >
        {index}
      </th>
      <td className="px-6 py-4">{completion.prediction}</td>
      <td className="px-6 py-4">{completion.score}</td>
      <div>{completion?.feedback}</div>
    </tr>
  );
}

interface DataTableProps {
  currentCompletions: Completion[];
  examples: any[]; // Replace 'any' with the actual type of 'examples'
}

export default function DataTable({
  currentCompletions,
  examples,
}: DataTableProps) {
  const [completion, setCompletion] = useState(null);

  return (
    <div className="relative overflow-x-auto">
      <DiffModal completion={completion} setCompletion={setCompletion} />
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              #
            </th>
            <th scope="col" className="px-6 py-3">
              Actual output
            </th>
            <th scope="col" className="px-6 py-3">
              Score
            </th>
          </tr>
        </thead>
        <tbody>
          {currentCompletions &&
            currentCompletions.map((c: Completion, i: number) => (
              <TableItem
                completion={c}
                index={i}
                example={examples[i]}
                setCompletion={setCompletion}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
