export default {
  'code-comments': {
    basePrompt:
      'Write me a ruby comment for the code below, respond with just the comment:',
    examples: [
      {
        input:
          'def deserialize(attr, val)\n        if defined_enums[attr] && val.is_a?(::String)\n        elsif PaperTrail.active_record_gte_7_0? && val.is_a?(ActiveRecord::Type::Time::Value)\n          val.instance_variable_get(:@time)\n        else\n          AttributeSerializerFactory.for(@klass, attr).deserialize(val)\n        end\n      end',
        target:
          '# Deserializes an attribute value\n      #\n      # @param [String] attr the attribute name\n      # @param [Object] val the attribute value\n      # @return [Object] the deserialized attribute value',
      },
      {
        input:
          'def parser_type_for_filename(filename)\n        ext = (File.extname(filename)[1..-1] || "").downcase\n        type = self.class.parser_type_for_extension(ext)\n        parser_type == :ruby18 && type == :ruby ? :ruby18 : type\n      end',
        target:
          '# Returns the parser type to use\n      #\n      # @param [String] filename the filename to use\n      # @return [Symbol] a parser type that matches the filename',
      },
      {
        input:
          'def validated_parser_type(type)\n          !defined?(::Ripper) && type == :ruby ? :ruby18 : type\n        end',
        target:
          '# Returns the validated parser type\n        #\n        # @param [Symbol] type the parser type to validate\n        # @return [Symbol] the validated parser type',
      },
      {
        input:
          'def tokenize(content, ptype = parser_type)\n          new(ptype).tokenize(content)\n        end',
        target:
          '# Tokenizes the block of code\n        #\n        # @param [String] content the block of code to tokenize\n        # @param [Symbol] ptype the parser type to use\n        # @return [Array] a list of tokens',
      },
    ],
  },
  'json-schema': {
    basePrompt: 'Return the JSON Schema for the given template string',
    examples: [
      {
        input:
          '{% if user.is_admin %}\n  <p>Welcome, admin {{ user.name }}!</p>\n{% else %}\n  <p>Welcome, user {{ user.name }}!</p>\n{% endif %}',
        target:
          '{\n  "type": "object",\n  "properties": {\n    "user": {\n      "type": "object",\n      "properties": {\n        "is_admin": {\n          "type": "boolean"\n        },\n        "name": {\n          "type": "string"\n        }\n      },\n      "required": ["is_admin", "name"]\n    }\n  },\n  "required": ["user"]\n}',
      },
      {
        input: 'Hello, {{ name }}!',
        target:
          '{\n  "type": "object",\n  "properties": {\n    "name": {\n      "type": "string"\n    }\n  },\n  "required": ["name"]\n}',
      },
      {
        input:
          '<ul>\n{% for item in items %}\n  <li>{{ item }}</li>\n{% endfor %}\n</ul>',
        target:
          '{\n  "type": "object",\n  "properties": {\n    "items": {\n      "type": "array",\n      "items": {\n        "type": "string"\n      }\n    }\n  },\n  "required": ["items"]\n}',
      },
      {
        input:
          '<ul>\n{% for user in users %}\n  <li>{{ user.name }}</li>\n{% else %}\n  <li>No users found.</li>\n{% endfor %}\n</ul>',
        target:
          '{\n  "type": "object",\n  "properties": {\n    "users": {\n      "type": "array",\n      "items": {\n        "type": "object",\n        "properties": {\n          "name": {\n            "type": "string"\n          }\n        },\n        "required": ["name"]\n      }\n    }\n  },\n  "required": ["users"]\n}',
      },
    ],
  },
  'customer-support': {
    examples: [
      {
        input:
          'I hope this message finds you well. I am writing to inquire about the status of my recent order (#87432), placed on July 5th, 2023. The items were expected to arrive by July 15th, but I have not yet received them. The order includes a set of kitchen appliances to be delivered to 456 Maple Lane, Chicago, IL, 60614. Your prompt assistance in tracking this shipment would be greatly appreciated.\n',
        target:
          '{\n  "id": 87432,\n  "date": "July 5th, 2023",\n  "shippingAddress": "456 Maple Lane, Chicago, IL, 60614",\n  "tone": "Neutral",\n  "type": "Missing Delivery"\n}',
      },
      {
        input:
          'My order (#32154) arrived damaged. I need a refund. The order date was June 25th, 2023, and it was a set of ceramic plates.\n\nThe delivery address was 123 Oak Street, Seattle, WA, 98101. Please process this as soon as possible.\n',
        target:
          '{\n  "id": 32154,\n  "date": "June 25th, 2023",\n  "shippingAddress": "123 Oak Street, Seattle, WA, 98101",\n  "tone": "Unhappy",\n  "type": "Damaged Item"\n}',
      },
      {
        input:
          "I got a package today (Order #45678) but it’s not what I ordered. There are several items I don't recognize. I ordered a set of gardening tools on July 15th, 2023, to be delivered to 321 Pine Ave, Denver, CO, 80203.\n\nWhat's going on? Could you check this for me?\n",
        target:
          '{\n  "id": 45678,\n  "date": "July 15th, 2023",\n  "shippingAddress": "321 Pine Ave, Denver, CO, 80203",\n  "tone": "Confused",\n  "type": "Wrong Item"\n}',
      },
      {
        input:
          "I'm writing to express my deep dissatisfaction with the service I've received. It's been over a month since I placed order #93572 on September 5th, 2023, and there's still no sign of it.\n\nThis delay is causing significant inconvenience, as the laptop I ordered is essential for my work.\n",
        target:
          '{\n  "id": 93572,\n  "date": "September 5th, 2023",\n  "shippingAddress": null,\n  "tone": "Unhappy",\n  "type": "Missing Delivery"\n}',
      },
      {
        input:
          'Hi - just noticed an item missing from my last order. It was a green sweater, size M. Ordered on August 20th, 2023. Please look into this and update me.\n\nThanks,\nHoward',
        target:
          '{\n  "id": null,\n  "date": "August 20th, 2023",\n  "shippingAddress": null,\n  "tone": "Neutral",\n  "type": "Missing Item"\n}',
      },
    ],
    basePrompt:
      'Extract the following fields from the email and respond with JSON.\n\n# Fields:\nid, date, shippingAddress, tone, type\n\n# Field Rules:\ntone: Must be Neutral, Unhappy, or Confused\ntype: Must be Missing Delivery, Damaged Item, Missing Item, or Wrong Item',
  },
  'tweet-sentiment': {
    basePrompt:
      'Given a tweet and its engagement metrics, return the sentiment and reach as JSON.',
    examples: [
      {
        input:
          '{\n  "tweet": "Loving the camera quality on my new OmegaPhone X. The night mode is incredible! #Photography",\n  "followerCount": 1752,\n  "engagement": {\n    "retweets": 120,\n    "likes": 200,\n    "replies": 40\n  }\n}',
        target:
          '{\n  "sentiment": "Positive",\n  "reach": {\n    "followerImpact": "Medium",\n    "engagementLevel": "High"\n  }\n}',
      },
      {
        input:
          '{\n  "tweet": "Frustrated with the battery life of my OmegaPhone X. Doesn\'t last a full day on a single charge. #BatteryIssues",\n  "followerCount": 10293,\n  "engagement": {\n    "retweets": 150,\n    "likes": 300,\n    "replies": 50\n  }\n}',
        target:
          '{\n  "sentiment": "Negative",\n  "reach": {\n    "followerImpact": "High",\n    "engagementLevel": "High"\n  }\n}',
      },
      {
        input:
          '{\n  "tweet": "The new OmegaPhone X looks sleek, but it\'s too slippery without a case. Almost dropped it twice! #DesignFlaw",\n  "followerCount": 238,\n  "engagement": {\n    "retweets": 1,\n    "likes": 20,\n    "replies": 10\n  }\n}',
        target:
          '{\n  "sentiment": "Negative",\n  "reach": {\n    "followerImpact": "Low",\n    "engagementLevel": "Low"\n  }\n}',
      },
    ],
  },
  'medicine-extraction': {
    basePrompt:
      'Extract medication from text {{medication}}. Provide a comma-separated list of the specific medications found in the text, including their forms if specified (e.g., topical, oral). Do not include any numbers, dosages, routes of administration, frequencies, or conditions treated. Respond with "No specific medication mentioned." if no specific medications are identified.',
    examples: [
      {
        input: {
          medication: '"Require IV Phenobarbital and post infusion monitoring"',
        },
        target: 'Phenobarbital',
      },
      {
        input: {
          medication:
            'Prilosec 20 mg p.o. q.d. Percocet 1-2 tabs p.o. q.6-8h. x5 days MVI 1 tab p.o. q.d. DISCHARGE FOLLOW-UP The patient was advised to follow-up with Dr. Edwards in clinic in one week . CONDITION ON DISCHARGE Stable condition . DISCHARGE DISPOSITION The patient was discharged to home . Dictated By SON RHALT SUBINTERN . Attending MOPAIGEIT S. SOLID M.D. KP91 KX681 6848 Batch 52108 Index No. GAEHS07465 D 03 01 00 T 03 01 00 CC 1.',
        },
        target: 'Prilosec, Percocet, MVI',
      },
      {
        input: {
          medication:
            'Dyazide one p.o. q.d. Propanolol 5 mg. p.o. t.i.d. Isordil 5 mg. p.o. t.i.d. Glaucoma drops',
        },
        target: 'Dyazide, Propanolol, Isordil',
      },
      {
        input: {
          medication:
            'Patient is prescribed Metformin 500 mg twice daily for diabetes management. Also taking Aspirin 81 mg once daily as a blood thinner. For seasonal allergies, using Flonase nasal spray once daily.',
        },
        target: 'Metformin, Aspirin, Flonase',
      },
      {
        input: {
          medication:
            'Current medications include Lisinopril 10 mg oral tablet once daily for hypertension, Simvastatin 20 mg oral tablet at bedtime for high cholesterol, and Albuterol inhaler 2 puffs every 4-6 hours as needed for shortness of breath.',
        },
        target: 'Lisinopril, Simvastatin, Albuterol',
      },
      {
        input: {
          medication:
            'Patient reports using over-the-counter Ibuprofen 200 mg tablets as needed for occasional headaches. Also applies Hydrocortisone cream 1% to areas of skin irritation twice daily. Takes a daily multivitamin supplement.',
        },
        target: 'Ibuprofen, Hydrocortisone',
      },
      {
        input: {
          medication:
            'Minor concern, no prescrpt req. Recommend otc for pain relief if needed.',
        },
        target: 'No specific medication mentioned.',
      },
    ],
  },
};
