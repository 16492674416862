import {RocketLaunchIcon, StopIcon} from '@heroicons/react/20/solid';
import {Button, Chip, Spinner, Tooltip} from '@nextui-org/react';
import React, {useEffect, useMemo, useState} from 'react';

import {ScoringTooltip} from './ScoringTooltip';
import {scoreToColor} from '../utils/scoreToColor';
import {
  prettyLatency,
  prettyScore,
  to100pointScale,
  to2SignificantFigures,
} from '../utils/string';

// Define the structure for a timeline event
type TimelineEvent = {
  id: number;
  content: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconBackground: string;
  target?: string;
  href?: string;
};

type Completion = {
  latency: number;
  prediction: string;
  score: number;
  scoreResponse: {
    score: number;
    scores: Array<number>;
  };
  scores: Array<number>;
  tokenCost: {
    blendedRatio: number;
    inputCost: number;
    outputCost: number;
    totalCost: number;
  };
  tokenUsage: {
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
  };
  usage: {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
  };
};

// Define the structure for a prompt node
type PromptNode = {
  id: string;
  reward: number;
  isDummy?: boolean;
  latency: number;
  completions?: Array<Completion>;
};

// Define the structure for task information
type TaskInfo = {
  taskName: string;
  criteria: Array<{
    criterion_name: string;
    assessment: string;
    scoring_guidelines: string;
  }>;
};

// Default timeline with initial event
const defaultTimeline: TimelineEvent[] = [
  {
    id: 1,
    content: 'Starting prompt optimization',
    icon: RocketLaunchIcon as React.ComponentType<unknown>,
    iconBackground: 'bg-gray-400',
  },
];

// Main component for displaying prompt optimization progress
export default function DisplayCard({
  timeline = defaultTimeline,
  promptNodes,
  onSelect,
  trainingRun,
  terminationThreshold,
  taskInfo,
  onStop,
}: {
  timeline?: TimelineEvent[];
  promptNodes: PromptNode[];
  onSelect: (index: number) => void;
  trainingRun: {model: string};
  terminationThreshold?: number;
  taskInfo?: TaskInfo;
  onStop: () => void;
}) {
  const [isStopped, setIsStopped] = useState(false);
  // Determine if optimization has started or if we are still extracting task name and criteria
  const hasStarted =
    timeline.length > 1 ||
    // TODO: Make this more robust, right now the only indication we are extracting task name and criteria is the first event's content field
    timeline[0].content !== 'Extracting task name and criteria';

  const [dots, setDots] = useState('');
  const [firstScore, setFirstScore] = useState<number | null>(null);
  const [, setEffectiveThreshold] = useState(1); // Default to 1
  const [bestNodeId, setBestNodeId] = useState<string | null>(null);

  // Sort and slice top 5 prompt nodes based on reward
  const topPromptNodes = [...promptNodes]
    .sort((a, b) => (b.reward ?? 0) - (a.reward ?? 0))
    .slice(0, 5);

  // Determine if optimization is complete (top score is 1 or best prompt found)
  const isComplete =
    topPromptNodes.length > 0 &&
    (topPromptNodes[0].reward === 1 ||
      timeline.some(event => event.content.includes('Best prompt found')));

  // Effect for animating loading dots
  useEffect(() => {
    if (!isComplete) {
      const interval = setInterval(() => {
        setDots(prev => (prev.length >= 3 ? '' : prev + '.'));
      }, 250);
      return () => clearInterval(interval);
    }
  }, [isComplete]);

  useEffect(() => {
    // Set the first score when it becomes available
    if (firstScore === null && promptNodes.some(node => node.reward != null)) {
      const firstReward = promptNodes.find(node => node.reward != null)?.reward;
      if (firstReward !== undefined) {
        setFirstScore(firstReward);
      }
    }
  }, [promptNodes, firstScore]);

  const sortedNodes = useMemo(() => {
    return [...promptNodes].sort((a, b) => {
      if (a.id === bestNodeId) return -1;
      if (b.id === bestNodeId) return 1;
      return (b.reward ?? 0) - (a.reward ?? 0);
    });
  }, [promptNodes, bestNodeId]);

  useEffect(() => {
    if (isComplete) {
      const topNodes = promptNodes.filter(node => node.reward === 1);
      // select one with the lowest latency
      const bestNode = topNodes.reduce((acc, n) => {
        return n.latency < acc.latency ? n : acc;
      }, topNodes[0]);

      if (bestNode) {
        setBestNodeId(bestNode.id);
      }
    }
  }, [isComplete, promptNodes, bestNodeId]);

  useEffect(() => {
    // Set the effective threshold
    setEffectiveThreshold(terminationThreshold ?? 1);
  }, [terminationThreshold]);

  // Render a prompt node
  const renderPromptNode = (node: PromptNode, allNodes: PromptNode[]) => {
    const originalIndex = allNodes.findIndex(n => n.id === node.id);
    const score = Number.isFinite(node.reward)
      ? prettyScore(node.reward!)
      : null;
    const bestNode = node.id === bestNodeId;

    const nodeOverallCost = (completions: Completion[]) => {
      return (
        '$' +
        to2SignificantFigures(
          (completions.reduce(
            (acc: number, cost: {tokenCost: {totalCost: number}}) => {
              return acc + cost.tokenCost.totalCost;
            },
            0,
          ) /
            completions.length) *
            1000,
        )
      );
    };

    const nodeOverallScoresByCriteria = (completions: Completion[]) => {
      if (!completions || completions.length === 0 || !taskInfo) {
        return [];
      }
      const meanScores = Array(taskInfo.criteria.length).fill(0);

      completions.forEach(completion => {
        if (!completion.scores) {
          return;
        }
        completion.scores.forEach((score, index) => {
          meanScores[index] += score;
        });
      });

      return meanScores
        .filter(Boolean)
        .map(score => score / completions.length);
    };

    return (
      <li
        onClick={() => onSelect(originalIndex)}
        key={node.id}
        className={`group cursor-pointer rounded-none border-1 ${
          bestNode
            ? 'border-[#28871B] bg-[#DCF1D4E5]'
            : 'border-gray-100 bg-gray-50 hover:bg-gray-100'
        } p-2 transition-all duration-200 ease-in-out hover:drop-shadow-xl`}
      >
        <div className="m-2 grid grid-cols-[15rem,5rem,5rem,repeat(auto-fit,_minmax(5rem,_1fr))]">
          <div className="flex w-[15rem] flex-row flex-nowrap items-center justify-between">
            <span className="font-medium">
              {originalIndex === 0
                ? 'Original Prompt'
                : `Prompt Candidate ${originalIndex}`}
            </span>
            {bestNode && (
              <div className="ml-6 mr-1 flex flex-row bg-[#E1F7E6] px-3 py-1">
                <img
                  src="/trophy.svg"
                  alt="trophy"
                  className="h-[11] w-[11] pr-1.5 text-[#28871B]"
                />
                <span className="font-chivo text-xs text-[#28871B]">Best</span>
              </div>
            )}
          </div>
          {node.completions && node.completions.length > 0 && (
            <>
              <div className="flex items-center justify-center">
                <span className="w-full text-center text-sm text-[#374151]">
                  {nodeOverallCost(node.completions)}
                </span>
              </div>
              <div className="flex items-center justify-center text-[#374151]">
                <span className="w-full text-center text-sm">
                  {prettyLatency(node.latency)}
                </span>
              </div>
              {nodeOverallScoresByCriteria(node.completions).map(
                (score, index) => (
                  <div
                    key={index}
                    className="mx-2 text-sm"
                    style={{
                      color: scoreToColor(score),
                    }}
                  >
                    {to100pointScale(score)}
                  </div>
                ),
              )}
            </>
          )}
          <div className="mr-6 flex items-center justify-end">
            <span
              className="text-sm"
              style={{
                color: score ? scoreToColor(node.reward) : 'gray',
              }}
            >
              {!hasStarted ? null : to100pointScale(score) || 'Evaluating...'}
            </span>
          </div>
        </div>
      </li>
    );
  };

  // Render a dummy node (placeholder)
  const renderDummyNode = (isComplete: boolean) => (
    <li
      key={`dummy-${Math.random()}`}
      className="rounded-none bg-gray-50 p-2 outline outline-gray-100"
    >
      <div className="flex items-center justify-between">
        <span className="font-medium">
          {isComplete
            ? 'Successful Candidate Found'
            : 'Generating Prompt Candidate'}
        </span>
      </div>
    </li>
  );

  // Render the list of top prompt nodes
  const renderTopPromptNodes = () => (
    <div className="mt-4">
      <div
        className={
          'm-2 grid grid-cols-[15rem,5rem,5rem,repeat(auto-fit,_minmax(5rem,_1fr))]'
        }
      >
        <div className="w-[15rem]" />
        <div className="flex items-center justify-center">
          <span className="ml-3 w-full text-center text-sm text-[#6B7280]">
            Cost
          </span>
        </div>
        <div className="flex items-center justify-center">
          <span className="ml-3 w-full text-center text-sm text-[#6B7280]">
            Latency
          </span>
        </div>
        {taskInfo &&
          taskInfo.criteria.map((criterion, index) => (
            <div key={index} className="flex items-center justify-center">
              <ScoringTooltip criteria={criterion}>
                <span className="text-sm text-[#6B7280] underline">
                  {criterion.criterion_name}
                </span>
              </ScoringTooltip>
            </div>
          ))}
        <div className="flex items-center justify-end">
          <Tooltip
            placement="bottom-start"
            content={
              <div className="flex flex-col p-3">
                <p className="mb2 text-sm">
                  Overall Score is calculated as a harmonic mean of the
                  individual criteria scores.
                  <br /> A harmonic mean is used instead of a simple average to
                  more heavily weight low scores,
                  <br /> ensuring that low scores on one or more criteria have a
                  larger impact on the Overall Score.
                </p>
              </div>
            }
          >
            <span className="text-sm text-[#6B7280] underline">
              Overall Score
            </span>
          </Tooltip>
        </div>
      </div>
      {/* Scrollable container for prompt nodes */}
      <div
        className="max-h-60 space-y-2 overflow-y-auto p-0.5"
        style={{scrollbarWidth: 'thin'}}
      >
        <ul>
          {sortedNodes.map(node =>
            node.isDummy
              ? renderDummyNode(isComplete)
              : renderPromptNode(node, promptNodes),
          )}
        </ul>
      </div>
    </div>
  );

  // Handle "Stop" button click
  const handleStop = () => {
    onStop();
    setIsStopped(true);
  };

  return (
    <div className={'mx-auto mb-4 flow-root max-w-full rounded-sm px-10 py-4'}>
      <div className="mb-4">
        <Chip
          size="sm"
          variant="flat"
          color="primary"
          className="text-xs"
          radius="none"
        >
          {trainingRun.model}
        </Chip>
      </div>

      <div className="flex space-x-4">
        <div className="flex-1">
          {isComplete ? (
            <h2 className="mb-4 w-full text-center text-lg font-extrabold">
              Optimization completed!
            </h2>
          ) : isStopped ? (
            <h2 className="mb-4 w-full text-center text-red-500">
              Process stopped
            </h2>
          ) : (
            <div className="mb-4 mt-4 flex w-full items-center justify-center">
              <Spinner className="my-2 mr-4" />
              <div className="flex items-center text-sm text-gray-600">
                <span>
                  {timeline[timeline.length - 1]?.content || 'No updates yet'}
                </span>
                <span className="w-6 text-left">{dots}</span>
              </div>
            </div>
          )}
          {renderTopPromptNodes()}
        </div>
      </div>

      <div className="mt-4 flex justify-center">
        {!isComplete && !isStopped && (
          <Button
            radius="none"
            color="danger"
            onClick={handleStop}
            startContent={<StopIcon className="h-3 w-3" />}
          >
            Stop
          </Button>
        )}
      </div>
    </div>
  );
}
