import {Chip} from '@nextui-org/react';
import React from 'react';

import ImportJsonButton from './ImportJsonButton';
import PromptEditor from './PromptEditor';
import {capitalise} from '../utils/string';

const Message: React.FC<{
  role: string;
  content: string;
  onChange: (data: {role: string; content: string}) => void;
}> = ({role, content, onChange}) => {
  const placeholder = `Enter your ${role} prompt or prompt template here. We support jinja2 where you can add variables using the syntax {{variable_name}} etc...`;
  return (
    <>
      <label
        htmlFor="input"
        className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {capitalise(role)}
      </label>
      <textarea
        id="prompt"
        name="prompt"
        rows={9}
        placeholder={placeholder}
        className="mb-4 mt-4 block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
        value={content}
        onChange={e => onChange({role, content: e.target.value})}
      />
    </>
  );
};

const setMessages = (prompt: any, messages: any) => {
  return {
    ...prompt,
    messages,
  };
};

const SHOULD_ALLOW_PROMPT_IMPORTS = false;

export default function PromptMessage({
  prompt,
  setPrompt,
  templateVariables,
}: {
  prompt: {messages: any[]};
  setPrompt: any;
  templateVariables: any;
}) {
  return (
    <>
      <PromptEditor
        messages={prompt.messages}
        onChange={messages => setPrompt(setMessages(prompt, messages))}
      />

      <div className="mt-6 flex items-center justify-end gap-x-2">
        <p className="flex-shrink-0 text-gray-600">Variables: </p>
        <div className="custom-scrollbar flex-grow overflow-x-auto whitespace-nowrap text-left">
          {templateVariables.length === 0 ? (
            <span className="text-gray-400">
              Add variables by wrapping them in &#123;&#123;&#125;&#125;
            </span>
          ) : (
            templateVariables.map((v, i) => (
              <Chip radius="sm" key={`variable-${i}`} className="mr-2">
                {v}
              </Chip>
            ))
          )}
        </div>
      </div>
      {SHOULD_ALLOW_PROMPT_IMPORTS ? (
        <div className="mt-6 flex items-center justify-end gap-x-2">
          <ImportJsonButton
            onImport={(json: any) => {
              setPrompt((prompt: any) => setMessages(prompt, json.messages));
            }}
          />
        </div>
      ) : null}
    </>
  );
}
