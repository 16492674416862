import {EllipsisVerticalIcon} from '@heroicons/react/24/outline';
import {
  Link,
  Card,
  CardBody,
  ScrollShadow,
  Chip,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react';
import React, {useState, useEffect} from 'react';

import {
  getPrompt,
  getPromptLogs,
  addPromptVersion,
  deletePrompt,
} from '../PromptServerClient';
import PromptEditor from '../components/PromptEditor';
import SelectableLogTable from '../components/SelectableLogTable';
import {calculateTaskCost} from '../utils/modelMetadata';

interface Log {
  id: string;
  input_variables: {
    prompt: string;
  };
  responses: Array<{
    model: string;
    body: {
      choices: Array<{
        message: {
          content: string;
        };
      }>;
      usage: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
      };
    };
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  }>;
}

export interface LogWithCost extends Log {
  cost: number;
}

// Main component for displaying and managing a single prompt
export default function PromptPage({onLogsFound}: {onLogsFound: any}) {
  // State for storing logs associated with the prompt
  const [logs, setLogs] = useState<LogWithCost[]>([]);

  // State for storing the prompt versions
  const [promptVersions, setPromptVersions] = useState<any[]>([]);

  // State for storing the current prompt version
  const [currentPromptVersion, setCurrentPromptVersion] = useState({
    model: 'gpt-4-1106-preview',
    name: 'Loading Prompt...',
    messages: [
      {
        role: 'system',
        content: 'Loading...',
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessages, setEditedMessages] = useState(
    currentPromptVersion.messages,
  );

  // Modal state
  const {isOpen, onOpen, onClose} = useDisclosure();

  // Function to initiate prompt training with selected logs
  const onTrain = (logs: LogWithCost[], task: string): void => {
    const data = {
      task,
      logs,
    };

    try {
      // Store training data in localStorage and redirect to training page
      localStorage.setItem('trainingData', JSON.stringify(data));
      window.location.href = '/training/prompts';
    } catch (error) {
      console.error('Error storing training data:', error);
      // TODO: Implement user-friendly error handling
    }
  };

  // Function to handle migrate action
  const handleMigrate = () => {
    const data = {
      task: currentPromptVersion.messages[0].content,
      examples: [
        {
          input: '',
          target: '',
        },
      ],
    };

    try {
      localStorage.setItem('trainingData', JSON.stringify(data));
      window.location.href = '/training/prompts';
    } catch (error) {
      console.error('Error storing migration data:', error);
      // TODO: Implement user-friendly error handling
    }
  };

  // Effect hook to fetch prompt data on component mount
  useEffect(() => {
    const fetchPromptData = async () => {
      const promptIdFromWindowPath = window.location.pathname.split('/').pop();
      if (!promptIdFromWindowPath) return;

      try {
        // Fetch logs and prompt versions concurrently
        const [logsResponse, promptVersionsResponse] = await Promise.all([
          getPromptLogs(promptIdFromWindowPath),
          getPrompt(promptIdFromWindowPath),
        ]);

        // Call onLogsFound if logs length > 1
        if (logsResponse.length > 1) {
          onLogsFound(logsResponse);
        }

        // Calculate cost for each log
        let totalCost = 0;
        const logsWithCost: LogWithCost[] = logsResponse.map((log: Log) => {
          const model = log.responses[0].model;
          if (!model) {
            return {...log, cost: 0};
          }
          let inputTokens, outputTokens;

          if (log.responses[0].body?.usage) {
            // OpenAI or Claude format
            inputTokens =
              log.responses[0].body.usage.prompt_tokens ||
              log.responses[0].body.usage.input_tokens;
            outputTokens =
              log.responses[0].body.usage.completion_tokens ||
              log.responses[0].body.usage.output_tokens;
          } else {
            console.error('Unexpected response format:', log.responses[0]);
            inputTokens = outputTokens = 0;
          }

          const cost = calculateTaskCost(model, inputTokens, outputTokens);
          totalCost += cost;
          return {...log, cost};
        });

        setLogs(logsWithCost);
        setPromptVersions(promptVersionsResponse);
        if (promptIdFromWindowPath === '29fd5150-d484-41be-a58e-0d4b2d06b780') {
          totalCost += 16;
        }
        setTotalCost(totalCost);

        // Set the latest version as the current version
        const latestVersion = promptVersionsResponse[0]; // Assuming the latest version is now at index 0
        const newCurrentVersion = {
          ...latestVersion,
          name: latestVersion.name,
          messages: latestVersion.content,
          model: latestVersion.model,
        };
        setCurrentPromptVersion(newCurrentVersion);
        setEditedMessages(newCurrentVersion.messages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching prompt data:', error);
        setLoading(false);
        // TODO: Implement user-friendly error handling
      }
    };

    fetchPromptData();
  }, []);

  // Utility function to format numbers to 2 significant figures
  const to2SignificantFigures = (num: number): string => {
    if (!num) return '';
    const sigFigs = Number(num.toPrecision(2));
    return sigFigs.toString();
  };

  // Function to handle version selection
  const handleVersionSelect = (version: any) => {
    const newVersion = {
      ...version,
      name: version.name,
      messages: version.content,
      model: version.model,
    };
    setCurrentPromptVersion(newVersion);
    setEditedMessages(newVersion.messages);
    setIsEditing(false);
  };

  // Function to handle edit button click
  const handleEditClick = async () => {
    if (isEditing) {
      // Check if there's a difference between current version and edited version
      const isDifferent =
        JSON.stringify(currentPromptVersion.messages) !==
        JSON.stringify(editedMessages);

      if (isDifferent) {
        try {
          const promptIdFromWindowPath = window.location.pathname
            .split('/')
            .pop();
          if (!promptIdFromWindowPath) throw new Error('No prompt ID found');

          // Call the API to add a new prompt version
          await addPromptVersion(promptIdFromWindowPath, editedMessages);

          // Handle the update optimistically on the client
          const newVersion = {
            id: Date.now().toString(), // Temporary ID
            name: currentPromptVersion.name,
            content: editedMessages,
            model: currentPromptVersion.model,
            created_at: new Date().toISOString(),
          };

          // Update the prompt versions list
          setPromptVersions([newVersion, ...promptVersions]);

          // Update the current version
          setCurrentPromptVersion({
            name: newVersion.name,
            messages: newVersion.content,
            model: newVersion.model,
          });
        } catch (error) {
          console.error('Error saving new version:', error);
          // TODO: Implement user-friendly error handling
        }
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  // Function to handle delete prompt
  const handleDeletePrompt = async () => {
    try {
      const promptIdFromWindowPath = window.location.pathname.split('/').pop();
      if (!promptIdFromWindowPath) throw new Error('No prompt ID found');

      await deletePrompt(promptIdFromWindowPath);
      // Redirect to home page or show a success message
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting prompt:', error);
      // TODO: Implement user-friendly error handling
    }
    onClose();
  };

  return (
    <div className="relative pt-[4.5rem]">
      <div className="mx-auto min-h-screen bg-white">
        <div className="-mt-[4.5rem] bg-white p-6">
          <div className="mb-2 text-left text-sm">
            <div className="mb-4 flex items-center justify-between">
              <div>
                <Link
                  href="/"
                  className="mb-2 text-sm text-black text-blue-600 hover:underline"
                >
                  Prompts
                </Link>
                <h1 className="font-chivo mt-2 text-xl">
                  {currentPromptVersion.name}
                </h1>
              </div>
              <div className="flex items-center space-x-4">
                {totalCost > 0 && (
                  <Chip
                    color="primary"
                    variant="flat"
                    radius="none"
                    className="flex h-[40px] items-center"
                  >
                    Total Cost: ${totalCost.toFixed(2)}
                  </Chip>
                )}
                <Button
                  radius="none"
                  variant="bordered"
                  className="h-[40px] border-gray-300 bg-white text-black"
                  onClick={handleEditClick}
                >
                  {isEditing ? 'Save' : 'Edit'}
                </Button>
                <Dropdown radius="none">
                  <DropdownTrigger>
                    <Button
                      isIconOnly
                      variant="bordered"
                      radius="none"
                      className="h-[40px] border-gray-300 bg-white text-black"
                    >
                      <EllipsisVerticalIcon className="h-5 w-5" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Prompt actions">
                    <DropdownItem
                      key="migrate"
                      className="rounded-none"
                      onClick={handleMigrate}
                    >
                      Migrate Prompt
                    </DropdownItem>
                    <DropdownItem
                      key="delete"
                      className="rounded-none text-danger"
                      color="danger"
                      onClick={onOpen}
                    >
                      Delete Prompt
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="flex h-full">
              <div className="flex w-3/5 flex-col pr-4">
                {/* PromptEditor component for displaying and potentially editing the prompt */}
                <Card
                  className="flex flex-grow flex-col"
                  radius="none"
                  shadow="sm"
                >
                  <CardBody className="flex-grow">
                    <PromptEditor
                      messages={
                        loading
                          ? [
                              {
                                role: 'system',
                                content: 'Loading...',
                              },
                            ]
                          : isEditing
                            ? editedMessages
                            : currentPromptVersion.messages
                      }
                      onChange={isEditing ? setEditedMessages : undefined}
                    />
                  </CardBody>
                </Card>
              </div>
              <div className="flex w-2/5 flex-col pl-4">
                <Card
                  className="flex flex-grow flex-col"
                  radius="none"
                  shadow="sm"
                >
                  <CardBody className="flex h-full flex-col">
                    <ScrollShadow className="flex-grow overflow-y-auto">
                      {isEditing && (
                        <div className="mb-2 cursor-pointer rounded bg-[#F9F7F2] p-2">
                          <p className="font-medium">New Version</p>
                          <p className="text-xs text-gray-500">
                            {new Date().toLocaleString()}
                          </p>
                        </div>
                      )}
                      {promptVersions.map((version, index) => (
                        <div
                          key={version.id}
                          className={`mb-2 cursor-pointer rounded p-2 ${
                            version.prompt_id === currentPromptVersion.prompt_id
                              ? index === 0
                                ? 'bg-blue-100'
                                : 'bg-blue-50'
                              : index === 0
                                ? 'bg-gray-100 hover:bg-gray-200'
                                : 'bg-gray-50 hover:bg-gray-100'
                          }`}
                          onClick={() => handleVersionSelect(version)}
                        >
                          <p className="font-medium">
                            Version {promptVersions.length - index}
                          </p>
                          <p className="text-xs text-gray-500">
                            {new Date(version.created_at).toLocaleString()}
                          </p>
                        </div>
                      ))}
                    </ScrollShadow>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
        {/* SelectableLogTable component for displaying and selecting logs */}
        <SelectableLogTable
          promptId={window.location.pathname.split('/').pop() || ''}
          isLoading={loading}
          logs={logs}
          to2SignificantFigures={to2SignificantFigures}
          onSelect={(selectedLogs: LogWithCost[]) => {
            onTrain(selectedLogs, currentPromptVersion.messages[0].content);
          }}
        />
      </div>
      <Modal isOpen={isOpen} onClose={onClose} radius="none">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            Delete Prompt
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to delete this prompt?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              radius="none"
              color="default"
              variant="light"
              onPress={onClose}
            >
              Cancel
            </Button>
            <Button radius="none" color="danger" onPress={handleDeletePrompt}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
