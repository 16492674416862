import {Button} from '@nextui-org/react';
import React from 'react';

interface Example {
  input: string;
  target: string;
}

interface Task {
  examples: Example[];
}

interface PromptObject {
  messages: Array<{content?: string}>;
  examples: Example[];
}

interface Props {
  task: Task;
  promptObject: PromptObject;
}

function ExportJsonButton({task, promptObject}: Props) {
  const exportToJson = () => {
    const {examples} = promptObject;
    if (!examples.length) {
      console.error('No examples to export');
      return;
    }

    const timestamp = new Date().getTime();
    const json = {
      name: `Exported Narrow AI Task JSON - ${timestamp}`,
      description:
        'Task JSON exported from Narrow AI Automated Prompt Engineer tool',
      examples: examples.map(({input, target}) => ({input, target})),
    };

    let jsonString;
    if (promptObject.messages[0]?.content) {
      const jsonWithBasePrompt = {
        ...json,
        basePrompt: promptObject.messages[0].content,
      };
      jsonString = JSON.stringify(jsonWithBasePrompt, null, 2);
    } else {
      jsonString = JSON.stringify(json, null, 2);
    }

    const blob = new Blob([jsonString], {type: 'application/json'});
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `APE-task-export-${timestamp}.json`;
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={exportToJson}
      className="relative -ml-px inline-flex items-center rounded-none bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      Export
    </Button>
  );
}

export default ExportJsonButton;
