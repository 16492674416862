import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/react';

import env from '../env';

export function sentryInit() {
  if (env.SENTRY_DSN) {
    init({
      dsn: env.SENTRY_DSN,
      integrations: [browserTracingIntegration(), replayIntegration()],

      // Tracing
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/prompt-api\.codethread\.ai/,
      ],

      // Session Replay
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
