import React, {useRef} from 'react';

function ImportJsonButton({onImport}) {
  const fileInputRef = useRef(null);
  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const jsonContent = e.target!.result as string;

        try {
          const json = JSON.parse(jsonContent) as any;
          console.log('Importing and sampling JSON:', json);

          // Prompt imports:
          if (json.messages) {
            onImport(json);
            return;
          }

          // Importing examples from jsonl fine tune format:
          if (
            Array.isArray(json) &&
            json.length > 0 &&
            json[0].prompt &&
            json[0].completion
          ) {
            (json as any).examples = json.map(example => {
              return {
                input: example.prompt,
                output: example.completion,
              };
            });
          }

          // Importing examples from BBH task JSON format:
          if (json.examples && Array.isArray(json.examples)) {
            // Ask user if they want to import entire dataset
            if (json.examples.length > 5) {
              const importAll = confirm(
                `Do you want to import all ${json.examples.length} examples? Press OK for Yes, Cancel for No (by default we import only 5 examples).`,
              );

              if (!importAll) {
                // Import only 5 random examples
                json.examples = json.examples
                  .sort(() => Math.random() - 0.5)
                  .slice(0, 5);
              }
            }

            if (json.task_prefix) {
              json.examples = json.examples.map(example => {
                if (example.target_scores) {
                  return {
                    ...example,
                    input:
                      json.task_prefix +
                      example.input +
                      '\n\n' +
                      'Options:' +
                      '\n' +
                      Object.keys(example.target_scores)
                        .map(key => key)
                        .join('\n'),
                  };
                }
                return {
                  ...example,
                  input: json.task_prefix + example.input,
                };
              });
            }
          }
          onImport(json);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as any).click();
    }
  };

  return (
    <div>
      <input
        style={{display: 'none'}}
        ref={fileInputRef}
        title="Import"
        type="file"
        accept=".json"
        onClick={(e: any) => {
          //This is added here, as due to the react input API, if a user tries to import the same file again it will not trigger onChange.
          e.target.value = ''; //Reference: https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
        }}
        onChange={handleFileChange}
      />
      <button
        onClick={handleButtonClick}
        type="button"
        className="relative -ml-px inline-flex items-center rounded-none bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Import
      </button>
    </div>
  );
}

export default ImportJsonButton;
