import {Select, SelectItem} from '@nextui-org/react';
import React from 'react';

const labelMap: {[key: string]: string} = {
  baseModel: 'Preferred Base Model',
  reviewerModel: 'Reviewer Model',
  writerModel: 'Writer Model',
};

export default function ModelSelector({
  modelConfig,
  setModelConfig,
  modelType,
  modelOptions,
}: {
  modelConfig: {[key: string]: string};
  setModelConfig: (modelConfig: {[key: string]: string}) => void;
  modelType: string;
  modelOptions: {name: string; value: string}[];
}) {
  const selectedModel = modelConfig[modelType];
  if (!labelMap[modelType]) {
    return null;
  }

  const handleSelectionChange = (newValue: string) => {
    localStorage.setItem(
      'codethread-modelconfig',
      JSON.stringify({
        ...modelConfig,
        [modelType]: newValue,
      }),
    );
    setModelConfig({...modelConfig, [modelType]: newValue});
  };

  return (
    <Select
      label={labelMap[modelType]}
      selectedKeys={selectedModel ? [selectedModel] : []}
      className="max-w-xs"
      radius="none"
      onSelectionChange={keys =>
        handleSelectionChange(Array.from(keys)[0] as string)
      }
      popoverProps={{
        placement: 'bottom',
      }}
    >
      {modelOptions.map(option => (
        <SelectItem key={option.value} value={option.value}>
          {option.name}
        </SelectItem>
      ))}
    </Select>
  );
}
