import {Textarea} from '@nextui-org/input';
import React from 'react';

interface Message {
  role: string;
  content: string;
}

interface PromptEditorProps {
  messages: Message[];
  placeholder?: string;
  onChange?: (newMessages: Message[]) => void;
}

function PromptEditor({messages, onChange, placeholder}: PromptEditorProps) {
  const handleMessageChange = (value: string, index: number) => {
    const newMessages = messages.map((message, i) =>
      i === index ? {...message, content: value} : message,
    );
    onChange?.(newMessages);
  };

  return (
    <div className="flex h-full flex-col">
      {messages.map((message, index) => (
        <Textarea
          key={index}
          label={message.role}
          value={message.content}
          onChange={event => handleMessageChange(event.target.value, index)}
          placeholder={
            placeholder ||
            `Your prompt message, variables should be enclosed in curly braces e.g:
Analyse this text {{text}}`
          }
          variant="bordered"
          radius="none"
          maxRows={20}
          className="flex-grow"
          minRows={3}
          size="lg"
        />
      ))}
    </div>
  );
}

export default PromptEditor;
