export const ColorMap: {[key: string]: string} = {
  '80-100': '#178217',
  '60-80': '#EBCD07',
  '40-60': '#DF9E1B',
  '20-40': '#EB5B07',
  '0-20': '#F31260',
};

export const scoreToColor = (score: number) => {
  if (score >= 0 && score <= 1) {
    if (score < 0.2) return ColorMap['0-20'];
    if (score < 0.4) return ColorMap['20-40'];
    if (score < 0.6) return ColorMap['40-60'];
    if (score < 0.8) return ColorMap['60-80'];
    return ColorMap['80-100'];
  } else {
    if (score < 20) return ColorMap['0-20'];
    if (score < 40) return ColorMap['20-40'];
    if (score < 60) return ColorMap['40-60'];
    if (score < 80) return ColorMap['60-80'];
    return ColorMap['80-100'];
  }
};
