type ModelMetadata = {
  provider: 'openai' | 'anthropic' | 'meta' | 'mistral' | 'google';
  // Token size of total context window:
  contextSize: number;
  // "Model Class" - e.g: gpt-4
  class: string;
  // Input dollar cost per m tokens:
  inputCost: number;
  // Output dollar cost per m tokens:
  outputCost: number;
};

type ModelMetadataMap = {
  [modelName: string]: ModelMetadata;
};

const modelDataMap: ModelMetadataMap = {
  'gpt-4o-mini': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4o',
    inputCost: 0.15,
    outputCost: 0.6,
  },
  'gpt-4o': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4o',
    inputCost: 5, // dollars per million tokens
    outputCost: 15, // dollars per million tokens
  },
  'gpt-4o-2024-05-13': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4o',
    inputCost: 5,
    outputCost: 15,
  },
  'gpt-4-turbo': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4-turbo',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-turbo-2024-04-09': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4-turbo',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-turbo-preview': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4-turbo',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-0125-preview': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-1106-preview': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-vision-preview': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4-vision',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4-1106-vision-preview': {
    provider: 'openai',
    contextSize: 128000,
    class: 'gpt-4-vision',
    inputCost: 10,
    outputCost: 30,
  },
  'gpt-4': {
    provider: 'openai',
    contextSize: 8192,
    class: 'gpt-4',
    inputCost: 30,
    outputCost: 60,
  },
  'gpt-4-0613': {
    provider: 'openai',
    contextSize: 8192,
    class: 'gpt-4',
    inputCost: 30,
    outputCost: 60,
  },
  'gpt-4-32k': {
    provider: 'openai',
    contextSize: 32768,
    class: 'gpt-4',
    inputCost: 60,
    outputCost: 120,
  },
  'gpt-4-32k-0613': {
    provider: 'openai',
    contextSize: 32768,
    class: 'gpt-4',
    inputCost: 60,
    outputCost: 120,
  },
  'gpt-3.5-turbo-0125': {
    provider: 'openai',
    contextSize: 16385,
    class: 'gpt-3.5',
    inputCost: 0.5,
    outputCost: 1.5,
  },
  'gpt-3.5-turbo': {
    provider: 'openai',
    contextSize: 16385,
    class: 'gpt-3.5',
    inputCost: 0.5,
    outputCost: 1.5,
  },
  'gpt-3.5-turbo-1106': {
    provider: 'openai',
    contextSize: 16385,
    class: 'gpt-3.5',
    inputCost: 1.0,
    outputCost: 2.0,
  },
  'gpt-3.5-turbo-instruct': {
    provider: 'openai',
    contextSize: 4096,
    class: 'gpt-3.5',
    inputCost: 1.5,
    outputCost: 2.0,
  },
  'gpt-3.5-turbo-16k': {
    provider: 'openai',
    contextSize: 16385,
    class: 'gpt-3.5',
    inputCost: 3.0,
    outputCost: 4.0,
  },
  'gpt-3.5-turbo-0613': {
    provider: 'openai',
    contextSize: 4096,
    class: 'gpt-3.5',
    inputCost: 1.5,
    outputCost: 2.0,
  },
  'gpt-3.5-turbo-16k-0613': {
    provider: 'openai',
    contextSize: 16385,
    class: 'gpt-3.5',
    inputCost: 3.0,
    outputCost: 4.0,
  },
  'claude-3-opus-20240229': {
    provider: 'anthropic',
    contextSize: 200000,
    class: 'claude-3',
    inputCost: 15,
    outputCost: 75,
  },
  'claude-3-sonnet-20240229': {
    provider: 'anthropic',
    contextSize: 200000,
    class: 'claude-3',
    inputCost: 3,
    outputCost: 15,
  },
  'claude-3-haiku-20240307': {
    provider: 'anthropic',
    contextSize: 200000,
    class: 'claude-3',
    inputCost: 0.25,
    outputCost: 1.25,
  },
  'claude-3-5-sonnet-20240620': {
    provider: 'anthropic',
    contextSize: 200000,
    class: 'claude-3',
    inputCost: 3,
    outputCost: 15,
  },
  'llama3-8b': {
    provider: 'meta',
    contextSize: 8000,
    class: 'llama-3',
    inputCost: 0.2,
    outputCost: 0.2,
  },
  'llama3-70b': {
    provider: 'meta',
    contextSize: 8000,
    class: 'llama-3',
    inputCost: 0.9,
    outputCost: 0.9,
  },
  'llama3.1-8b': {
    provider: 'meta',
    contextSize: 8000,
    class: 'llama-3.1',
    inputCost: 0.2,
    outputCost: 0.2,
  },
  'llama3.1-70b': {
    provider: 'meta',
    contextSize: 8000,
    class: 'llama-3.1',
    inputCost: 0.9,
    outputCost: 0.9,
  },
  'llama3.1-405b': {
    provider: 'meta',
    contextSize: 8000,
    class: 'llama-3.1',
    inputCost: 3,
    outputCost: 3,
  },
  'llama3.2-1b': {
    provider: 'meta',
    contextSize: 128000,
    class: 'llama-3.2',
    inputCost: 0.1,
    outputCost: 0.1,
  },
  'llama3.2-3b': {
    provider: 'meta',
    contextSize: 128000,
    class: 'llama-3.2',
    inputCost: 0.1,
    outputCost: 0.1,
  },
  'llama3.2-11b': {
    provider: 'meta',
    contextSize: 128000,
    class: 'llama-3.2',
    inputCost: 0.2,
    outputCost: 0.2,
  },
  'llama3.2-90b': {
    provider: 'meta',
    contextSize: 128000,
    class: 'llama-3.2',
    inputCost: 0.9,
    outputCost: 0.9,
  },
  'mixtral-8x7b': {
    provider: 'mistral',
    contextSize: 32000,
    class: 'mixtral',
    inputCost: 0.6,
    outputCost: 0.6,
  },
  'mistral-large-2407': {
    provider: 'mistral',
    contextSize: 128000,
    class: 'mistral-large',
    inputCost: 3,
    outputCost: 9,
  },
  'gemini-1.5-flash': {
    provider: 'google',
    contextSize: 1048576,
    class: 'gemini',
    inputCost: 0.075,
    outputCost: 0.3,
  },
  'gemini-1.5-flash-8b': {
    provider: 'google',
    contextSize: 1048576,
    class: 'gemini',
    inputCost: 0.0375,
    outputCost: 0.15,
  },
  'gemini-1.5-pro': {
    provider: 'google',
    contextSize: 1048576,
    class: 'gemini',
    inputCost: 1.25,
    outputCost: 5,
  },
  'gemini-1.0-pro': {
    provider: 'google',
    contextSize: 32000,
    class: 'gemini',
    inputCost: 0.13,
    outputCost: 0.38,
  },
  'open-mixtral-8x7b': {
    provider: 'mistral',
    contextSize: 32000,
    class: 'mixtral',
    inputCost: 0.7,
    outputCost: 0.7,
  },
  'open-mixtral-8x22b': {
    provider: 'mistral',
    contextSize: 32000,
    class: 'mixtral',
    inputCost: 2,
    outputCost: 6,
  },
  'codestral-latest': {
    provider: 'mistral',
    contextSize: 32000,
    class: 'codestral',
    inputCost: 1,
    outputCost: 3,
  },
  'open-mistral-nemo': {
    provider: 'mistral',
    contextSize: 128000,
    class: 'mistral-nemo',
    inputCost: 0.3,
    outputCost: 0.3,
  },
};

export default modelDataMap;

export const getModelProvider = (model: string): string => {
  const modelMetadata = modelDataMap[model || 'gpt-4-turbo'];
  return modelMetadata.provider || 'openai';
};

export const getModelMetadata = (model: string): ModelMetadata => {
  if (modelDataMap[model]) {
    return modelDataMap[model]; // Return the metadata if the model is found
  }

  // search if some keys in the modelDataMap are similar to the model provided
  const similarModelName = Object.keys(modelDataMap).find(
    key => model.includes(key) || key.includes(model),
  );

  if (similarModelName) {
    return modelDataMap[similarModelName];
  }

  throw new Error(`Model ${model} not found in modelDataMap`);
};

const NANO_DOLLARS = 1_000_000_000;

export const calculateTaskCost = (
  model: string,
  inputTokens: number,
  outputTokens: number,
): number => {
  const modelMetadata = getModelMetadata(model);

  const inputCost = Math.round(
    (inputTokens * modelMetadata.inputCost * NANO_DOLLARS) / 1_000_000,
  );
  const outputCost = Math.round(
    (outputTokens * modelMetadata.outputCost * NANO_DOLLARS) / 1_000_000,
  );

  return (inputCost + outputCost) / NANO_DOLLARS;
};

export const calculate1000TaskCost = (
  model: string,
  avgInputTokens: number,
  avgOutputTokens: number,
): number => {
  const costInNanoDollars = Math.round(
    calculateTaskCost(model, avgInputTokens, avgOutputTokens) *
      NANO_DOLLARS *
      1000,
  );
  return costInNanoDollars / NANO_DOLLARS;
};

export const estimateCostDifferencePer1000 = (
  initialModel: string,
  newModel: string,
  avgInputTokens: number,
  avgOutputTokens: number,
): {absoluteDifference: number; percentageDifference: number} => {
  const initialCostNanoDollars = Math.round(
    calculate1000TaskCost(initialModel, avgInputTokens, avgOutputTokens) *
      NANO_DOLLARS,
  );
  const newCostNanoDollars = Math.round(
    calculate1000TaskCost(newModel, avgInputTokens, avgOutputTokens) *
      NANO_DOLLARS,
  );

  const absoluteDifferenceNanoDollars =
    newCostNanoDollars - initialCostNanoDollars;
  const percentageDifference =
    initialCostNanoDollars !== 0
      ? (absoluteDifferenceNanoDollars / initialCostNanoDollars) * 100
      : newCostNanoDollars === 0
        ? 0
        : Infinity;

  return {
    absoluteDifference: absoluteDifferenceNanoDollars / NANO_DOLLARS,
    percentageDifference: Math.round(percentageDifference * 100) / 100, // Round to 2 decimal places
  };
};
