import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Button,
  Selection,
  Chip,
  Tooltip,
  Link,
} from '@nextui-org/react';
import React, {useState, useCallback} from 'react';

import {LogWithCost} from '../pages/PromptPage';

interface SelectableLogTableProps {
  promptId: string;
  logs: any[];
  to2SignificantFigures: (num: number) => string;
  onSelect: (selectedLogs: LogWithCost[]) => void;
  isLoading: boolean;
}

const SelectableLogTable: React.FC<SelectableLogTableProps> = ({
  logs = [],
  promptId,
  to2SignificantFigures,
  onSelect,
  isLoading,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));

  const columns = [
    {key: 'id', label: 'ID'},
    {key: 'status', label: 'STATUS'},
    {key: 'model', label: 'MODEL'},
    {key: 'input', label: 'INPUT'},
    {key: 'response', label: 'RESPONSE'},
    {key: 'tokens', label: 'TOKENS'},
    {key: 'cost', label: 'COST'},
  ];

  const renderCell = useCallback(
    (log: LogWithCost, columnKey: React.Key) => {
      switch (columnKey) {
        case 'id':
          return (
            <Link
              href={`/prompts/${promptId}/logs/${log.id}`}
              className="text-blue-500 hover:underline"
            >
              <div className="max-w-[100px] truncate">{log.id}</div>
            </Link>
          );
        case 'status':
          return (
            <Chip
              color={log.responses[0]?.status === 200 ? 'success' : 'danger'}
              variant="flat"
              radius="none"
              size="sm"
            >
              {log.responses[0]?.status === 200 ? 'Ok' : 'Failed'}
            </Chip>
          );
        case 'model':
          return (
            <div className="max-w-[100px] truncate">
              {log.responses[0].model || 'Request failed'}
            </div>
          );
        case 'input': {
          const jsonString = log.input_variables
            ? JSON.stringify(log.input_variables, null, 2)
            : 'No input variables';
          return (
            <div
              style={{
                border: '1px solid #ccc',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '0.75em',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '500px',
                cursor: 'text',
                position: 'relative',
              }}
            >
              {jsonString}
              <span
                style={{
                  position: 'absolute',
                  right: '4px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
                className="hover:opacity-100"
              >
                📋
              </span>
            </div>
          );
        }
        case 'response': {
          const responseContent =
            (log.responses &&
              (log.responses[0]?.body?.choices?.[0]?.message?.content || // OpenAI format
                log.responses[0]?.body?.content?.[0]?.text || // Anthropic format
                log.responses[0]?.body?.code || // Error code
                log.responses[0]?.body?.error?.code || // Nested error code
                log.responses[0]?.content)) || // Fallback for other formats
            log.responses[0].body ||
            'No response available';
          return (
            <div
              style={{
                border: '1px solid #ccc',
                padding: '4px',
                borderRadius: '4px',
                fontSize: '0.75em',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '200px',
                cursor: 'text',
                position: 'relative',
              }}
            >
              {responseContent}
              <span
                style={{
                  position: 'absolute',
                  right: '4px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
                className="hover:opacity-100"
              >
                📋
              </span>
            </div>
          );
        }
        case 'tokens': {
          const {
            prompt_tokens = 0,
            completion_tokens = 0,
            total_tokens = 0,
          } = log.responses[0]?.body?.usage || {};

          if (
            prompt_tokens === null ||
            completion_tokens === null ||
            total_tokens === null
          ) {
            return '0 (0:0)';
          }
          return `${total_tokens || 0} (${prompt_tokens || 0}:${completion_tokens || 0})`;
        }
        case 'cost':
          return `$${to2SignificantFigures(log.cost) || 0}`;
        default:
          return null;
      }
    },
    [to2SignificantFigures],
  );

  const onSelectionChange = (selection: Selection) => {
    setSelectedKeys(selection);
  };

  return (
    <div className="p-6">
      <div className="mb-4 text-right">
        <Tooltip
          content="Select logs to use as a training dataset"
          isDisabled={selectedKeys instanceof Set && selectedKeys.size !== 0}
        >
          <Chip size="sm" radius="sm">
            {selectedKeys instanceof Set && selectedKeys.size
              ? `${selectedKeys.size} ${selectedKeys.size === 1 ? 'log' : 'logs'} selected`
              : 'No logs selected yet'}
          </Chip>
        </Tooltip>
        {selectedKeys instanceof Set && selectedKeys.size ? (
          <Button
            className="ml-4"
            color="primary"
            radius="none"
            onClick={() => {
              const selectedLogs = logs.filter(log => selectedKeys.has(log.id));
              onSelect(selectedLogs);
            }}
          >
            Migrate
          </Button>
        ) : (
          ''
        )}
      </div>
      <Table
        radius="none"
        aria-label="Selectable log table"
        selectionMode="multiple"
        isCompact
        isHeaderSticky
        fullWidth
        selectedKeys={selectedKeys}
        onSelectionChange={onSelectionChange}
        checkboxesProps={{radius: 'none'}}
        className="text-xs"
      >
        <TableHeader columns={columns}>
          {column => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody
          items={logs}
          isLoading={isLoading}
          emptyContent="You don't have any logs yet."
          loadingContent="Loading your prompt data."
        >
          {log => (
            <TableRow key={log.id}>
              {columnKey => <TableCell>{renderCell(log, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default SelectableLogTable;
