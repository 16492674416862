export function parseNunjucksTemplate(template: string): any[] {
  const forLoopRegex = /\{%\s*for\s+(.*?)\s+in\s+(.*?)\s*%\}/g;
  const variableRegex = /\{\{\s*(.*?)\s*\}\}/g;
  const topLevelVariables = new Set();
  let match;

  // Capture 'for ... in ...' loop variables and mark their scope
  const forLoopVariables = new Set();
  while ((match = forLoopRegex.exec(template)) !== null) {
    const iterableVariable = match[2].trim();
    topLevelVariables.add(iterableVariable);
    match[1].split(',').forEach(variable => {
      forLoopVariables.add(variable.trim());
    });
  }

  // Capture standalone variable usages
  while ((match = variableRegex.exec(template)) !== null) {
    const variableName = match[1].trim();
    if (!forLoopVariables.has(variableName)) {
      topLevelVariables.add(variableName);
    }
  }

  return Array.from(topLevelVariables);
}
