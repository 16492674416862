import {Card, CardBody, CardHeader, CardFooter} from '@nextui-org/react';
import React from 'react';

export default function Login({auth}: {auth: React.ReactNode}) {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <Card className="w-full max-w-xl" radius="none">
        <CardHeader className="flex flex-col items-center pb-0 pt-6">
          <h1 className="font-chivo text-2xl font-light text-primary">
            Welcome to Narrow AI
          </h1>
          <p className="text-lg text-gray-600">
            Start saving up to 95% of your LLM costs today.
          </p>
        </CardHeader>
        <CardBody className="max-w-m py-4">{auth}</CardBody>
      </Card>
    </div>
  );
}
