import {RadioGroup, Radio, Tooltip} from '@nextui-org/react';
import React from 'react';

interface ModeSwitchProps {
  defaultValue: string;
  setEvaluationMode: (value: string) => void;
}

function ModeSwitch({defaultValue, setEvaluationMode}: ModeSwitchProps) {
  return (
    <RadioGroup
      orientation={'horizontal'}
      onChange={e => setEvaluationMode(e.target.value)}
      defaultValue={defaultValue}
    >
      <Tooltip
        placement="bottom-start"
        content={
          <p className="font-chivo m-2">
            Optimizes for similarity to examples provided
          </p>
        }
      >
        <Radio value={'hard'}>
          <span className="font-chivo">Hard</span>
        </Radio>
      </Tooltip>
      <Tooltip
        placement="bottom-start"
        content={
          <p className="font-chivo m-2">
            Optimizes for metrics extracted from your
            <br />
            prompt and examples
          </p>
        }
      >
        <Radio value={'soft'} className="ml-4">
          <span className="font-chivo">Soft</span>
        </Radio>
      </Tooltip>
    </RadioGroup>
  );
}

export default ModeSwitch;
