import {
  ArrowPathIcon,
  ArrowUpTrayIcon,
  ChartBarIcon,
  CommandLineIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  BookOpenIcon,
  CogIcon,
} from '@heroicons/react/24/outline';
import {InboxStackIcon} from '@heroicons/react/24/solid';
import {Link, Button, Card, Snippet, Tabs, Tab} from '@nextui-org/react';
import React, {useState, useEffect} from 'react';

const DocsPage = () => {
  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  const tableOfContents = [
    {id: 'overview', title: 'Overview'},
    {id: 'concepts', title: 'Key Concepts'},
    {
      id: 'prompt-migration',
      title: 'Step 1: Run your first prompt optimization',
    },
    {id: 'add-prompt', title: 'Step 2: Add your own prompts'},
    {
      id: 'collect-data',
      title: 'Step 3: Connect to your application',
    },
    {id: 'best-practices', title: 'Best Practices'},
  ];

  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {threshold: 0.8},
    );

    tableOfContents.forEach(item => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="relative min-h-screen bg-white pt-[4.5rem]">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row">
          {/* Left column: Content */}
          <div className="mt-2 lg:w-3/4 lg:pl-8">
            <div className="mb-2 text-left text-sm">
              <Link
                href="/"
                className="mb-2 text-sm text-black text-blue-600 hover:underline"
              >
                Home
              </Link>
              <h1 className="font-chivo mb-6 text-3xl">Documentation</h1>

              {/* Overview Section */}
              <Card
                id="overview"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <InformationCircleIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Overview
                </h2>
                <p className="mb-6 text-lg leading-relaxed text-gray-700">
                  Auto Prompt Optimization is a powerful tool designed to reduce
                  costs while maintaining the quality of AI responses. This
                  system helps you migrate from larger, more expensive language
                  models to smaller, more efficient ones without sacrificing
                  performance.
                </p>
                <h3 className="mb-4 mt-2 text-xl font-semibold">
                  Potential Savings
                </h3>
                <p className="mb-4 text-lg leading-relaxed text-gray-700">
                  By leveraging our Auto Prompt Optimization, you can achieve
                  significant cost savings:
                </p>
                <ul className="list-disc space-y-2 pl-8 text-lg text-gray-700">
                  <li>Up to 95% reduction in AI processing costs</li>
                  <li>
                    For example, migrating from a 70B parameter model to an 8B
                    model can result in a 13:1 cost saving ratio
                  </li>
                </ul>
              </Card>

              {/* Key Concepts Section */}
              <Card
                id="concepts"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <BookOpenIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Key Concepts
                </h2>
                <ul className="space-y-4">
                  <li className="mb-4">
                    <strong className="text-lg">Prompt:</strong>
                    <p className="text-lg leading-relaxed text-gray-700">
                      Input text designed to guide AI models in generating
                      specific outputs or performing particular tasks. You can
                      save a prompt to your account and attempt to migrate it to
                      a smaller model using our migration tool.
                    </p>
                  </li>
                  <li className="mb-4">
                    <strong className="text-lg">Prompt Versions:</strong>
                    <p className="text-lg leading-relaxed text-gray-700">
                      Different iterations or variations of a prompt, each
                      tailored to optimize performance for specific models or
                      use cases, find these on your prompt page.
                    </p>
                  </li>
                  <li className="mb-4">
                    <strong className="text-lg">Logs:</strong>
                    <p className="text-lg leading-relaxed text-gray-700">
                      Once you connect to our API, you can start to produce logs
                      which are tied to a specific prompt, helping you
                      understand the results and cost of your LLM features.
                    </p>
                    <br />
                    <p className="text-lg leading-relaxed text-gray-700">
                      These logs can also be used to curate a dataset for
                      migration!
                    </p>
                  </li>
                  <li className="mb-4">
                    <strong className="text-lg">Migration:</strong>
                    <p className="text-lg leading-relaxed text-gray-700">
                      The process of adapting prompts from one AI model to
                      another, typically from larger to smaller models, while
                      maintaining or improving performance.
                    </p>
                  </li>
                  <li className="mb-4">
                    <strong className="text-lg">Models:</strong>
                    <p className="text-lg leading-relaxed text-gray-700">
                      The AI language models of varying sizes and capabilities
                      used to process prompts and generate responses, each with
                      its own strengths and efficiency characteristics.
                    </p>
                  </li>
                </ul>
                <div className="mt-6">
                  <strong className="text-lg">Supported Models:</strong>
                  <p className="mb-4 mt-2 text-lg leading-relaxed text-gray-700">
                    We support a wide range of models from various providers:
                  </p>
                  <ul className="list-disc space-y-2 pl-8 text-lg text-gray-700">
                    <li>OpenAI: GPT-4 series, GPT-3.5 series</li>
                    <li>Anthropic: Claude-3 series and Sonnet 3.5</li>
                    <li>Meta: Llama 3 and 3.1 series (8B, 70B, 405B)</li>
                    <li>Mistral: Mixtral-8x7B</li>
                    <li>Google: Gemini series</li>
                  </ul>
                  <p className="mt-4 text-lg leading-relaxed text-gray-700">
                    Currently, the most popular migration path is to Llama 3.1
                    models, particularly the 70B variant, which offers a good
                    balance of performance and efficiency. However, we can also
                    help you find the best model within a single platform like
                    Anthropic or OpenAI, optimizing your workflow while staying
                    within your preferred ecosystem.
                  </p>
                </div>
              </Card>

              <Card
                id="prompt-migration"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <ArrowPathIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Run your first prompt optimization
                </h2>
                <p className="mb-6 text-lg leading-relaxed text-gray-700">
                  Discover how to optimize your prompts by migrating from larger
                  to smaller language models. This process can significantly
                  reduce costs and improve efficiency.
                </p>
                <ul
                  role="list"
                  className="divide-y divide-gray-200 border-b border-t border-gray-200"
                >
                  {[
                    {
                      name: 'Migrate your data extraction prompt to Llama3 70b',
                      description:
                        'See how we can step down a medical extraction use case to 70b for cost-efficiency.',
                      href: '/training/prompts?template=medicine-extraction',
                      iconColor: 'bg-purple-500',
                      icon: CommandLineIcon,
                    },
                    {
                      name: 'Migrate an email categorization prompt to Llama3 8b',
                      description:
                        'See how to optimize categorization prompts for compact language models without sacrificing quality.',
                      href: '/training/prompts?template=customer-support',
                      iconColor: 'bg-yellow-500',
                      icon: InboxStackIcon,
                    },
                  ].map((item, itemIdx) => (
                    <li key={itemIdx} className="hover:bg-gray-50">
                      <div className="group relative flex items-start space-x-4 py-6">
                        <div className="flex-shrink-0">
                          <span
                            className={classNames(
                              item.iconColor,
                              'inline-flex h-12 w-12 items-center justify-center rounded-lg',
                            )}
                          >
                            <item.icon
                              className="h-8 w-8 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div className="mb-1 text-lg font-medium text-gray-900">
                            <a href={item.href}>
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </div>
                          <p className="text-base leading-relaxed text-gray-500">
                            {item.description}
                          </p>
                        </div>
                        <div className="flex-shrink-0 self-center">
                          <ChevronRightIcon
                            className="h-6 w-6 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card>
              <Card
                id="add-prompt"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <ArrowUpTrayIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Add your own prompt
                </h2>
                <p className="mb-4 text-lg leading-relaxed text-gray-700">
                  Now that you&#39;ve run your first prompt optimization,
                  it&#39;s time to add your own prompts to the system to begin
                  your migrations.
                </p>
                <p className="mb-6 text-lg leading-relaxed text-gray-700">
                  To get started, click the "Add A Prompt Now" button below.
                  You'll be guided through the process of creating a new prompt,
                  which you can then use to run your own optimization and
                  migration process.
                </p>
                <div>
                  <Button
                    as={Link}
                    href="/prompts/create"
                    radius="none"
                    color="primary"
                  >
                    Add A Prompt Now
                  </Button>
                </div>
              </Card>
              <Card
                id="collect-data"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <ChartBarIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Collect prompt data from your application
                </h2>
                <p className="mb-4 text-lg leading-relaxed text-gray-700">
                  Unlock the power of data-driven prompt optimization. Learn how
                  to effectively collect, analyze, and leverage prompt data from
                  your application to continuously improve AI performance.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-gray-700">
                  Stay tuned for comprehensive instructions and practical code
                  examples, coming soon to help you implement robust data
                  collection strategies.
                </p>

                <h3 className="mb-4 mt-6 text-xl font-semibold">
                  Tracking API Endpoint
                </h3>
                <p className="mb-4 text-lg leading-relaxed text-gray-700">
                  To track and analyze your prompt data, utilize our dedicated
                  API endpoint:
                </p>
                <Snippet
                  className="overflow-x-auto rounded-md bg-gray-100 p-4"
                  symbol=""
                  radius="none"
                >
                  https://prompt-api.getnarrow.ai/prompts/:prompt-id/track
                </Snippet>
                <p className="mb-2 mt-4 text-lg leading-relaxed text-gray-700">
                  This endpoint accepts a POST request with a JSON payload
                  containing two main components:
                </p>
                <ol className="mb-4 list-inside list-decimal text-lg leading-relaxed text-gray-700">
                  <li>
                    <strong>inputVariables</strong>: An object containing the
                    variables used in your prompt.
                  </li>
                  <li>
                    <strong>body</strong>: The complete response from the LLM,
                    including both successful completions and any error
                    responses.
                  </li>
                </ol>
                <p className="mb-2 text-lg leading-relaxed text-gray-700">
                  Here's an example of the JSON structure:
                </p>
                <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
                  <code
                    className="cursor-pointer text-sm"
                    onClick={() =>
                      navigator.clipboard.writeText(`{
  "inputVariables": {
    "user_query": "What's the weather like today?",
    "location": "New York"
  },
  "body": {
    "id": "chatcmpl-123",
    "object": "chat.completion",
    "created": 1677652288,
    "model": "gpt-3.5-turbo-0613",
    "choices": [{
      "index": 0,
      "message": {
        "role": "assistant",
        "content": "It's sunny with a high of 75°F (24°C) and a low of 60°F (16°C)."
      },
      "finish_reason": "stop"
    }],
    "usage": {
      "prompt_tokens": 25,
      "completion_tokens": 54,
      "total_tokens": 79
    }
  }
}`)
                    }
                  >
                    {`{
  "inputVariables": {
    "user_query": "What's the weather like today?",
    "location": "New York"
  },
  "body": {
    "id": "chatcmpl-123",
    "object": "chat.completion",
    "created": 1677652288,
    "model": "gpt-3.5-turbo-0613",
    "choices": [{
      "index": 0,
      "message": {
        "role": "assistant",
        "content": "It's sunny with a high of 75°F (24°C) and a low of 60°F (16°C)."
      },
      "finish_reason": "stop"
    }],
    "usage": {
      "prompt_tokens": 25,
      "completion_tokens": 54,
      "total_tokens": 79
    }
  }
}`}
                  </code>
                </pre>
                <p className="mb-4 mt-4 text-lg leading-relaxed text-gray-700">
                  Here are some quick examples of how to use the API endpoint:
                </p>
                <Tabs aria-label="API Examples">
                  <Tab key="curl" title="cURL">
                    <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
                      <code>
                        {
                          'curl -X POST https://prompt-api.getnarrow.ai/prompts/:prompt-id/track \
-H "Content-Type: application/json" \
-H "Authorization: Bearer <NARROW_AI_API_KEY>" \
-d \'{"inputVariables":{"user_query":"What\'s the weather like today?","location":"New York"},"body":<LLM_API_RESPONSE>}\''
                        }
                      </code>
                    </pre>
                  </Tab>
                  <Tab key="python" title="Python">
                    <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
                      <code>
                        {`import requests

url = "https://prompt-api.getnarrow.ai/prompts/:prompt-id/track"
headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer <NARROW_AI_API_KEY>"
}
data = {
    "inputVariables": {
        "user_query": "What's the weather like today?",
        "location": "New York"
    },
    "body": <LLM_API_RESPONSE>
}
response = requests.post(url, headers=headers, json=data)
print(response.json())`}
                      </code>
                    </pre>
                  </Tab>
                  <Tab key="javascript" title="JavaScript">
                    <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
                      <code>
                        {`fetch('https://prompt-api.getnarrow.ai/prompts/:prompt-id/track', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer <NARROW_AI_API_KEY>'
  },
  body: JSON.stringify({
    inputVariables: {
      user_query: "What's the weather like today?",
      location: "New York"
    },
    body: <LLM_API_RESPONSE>
  })
})
.then(response => response.json())
.then(data => console.log(data))`}
                      </code>
                    </pre>
                  </Tab>
                  <Tab key="go" title="Go">
                    <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
                      <code>
                        {`package main

import (
	"bytes"
	"encoding/json"
	"net/http"
)

func main() {
	url := "https://prompt-api.getnarrow.ai/prompts/:prompt-id/track"
	data := map[string]interface{}{
		"inputVariables": map[string]string{
			"user_query": "What's the weather like today?",
			"location":   "New York",
		},
		"body": "<LLM_API_RESPONSE>",
	}
	jsonData, _ := json.Marshal(data)
	req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
	req.Header.Set("Content-Type", "application/json")
	req.Header.Set("Authorization", "Bearer <NARROW_AI_API_KEY>")
	client := &http.Client{}
	client.Do(req)
}`}
                      </code>
                    </pre>
                  </Tab>
                </Tabs>
                <p className="mt-4 text-lg leading-relaxed text-gray-700">
                  By sending your prompt data to this endpoint, you enable our
                  system to collect valuable insights, helping you optimize your
                  AI interactions and improve overall performance. This
                  data-driven approach allows for continuous refinement of your
                  prompts and more effective AI-powered solutions.
                </p>
              </Card>
              <Card
                id="best-practices"
                className="mb-8 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50"
              >
                <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                  <CogIcon className="mr-3 h-7 w-7 text-gray-600" />
                  Best Practices
                </h2>
                <p className="mb-6 text-lg leading-relaxed text-gray-700">
                  Maximize the effectiveness of your prompt optimization efforts
                  with these key best practices:
                </p>
                <ul className="list-disc space-y-4 pl-8 text-lg text-gray-700">
                  <li className="leading-relaxed">
                    Regularly test and iterate on your prompts to ensure optimal
                    performance.
                  </li>
                  <li className="leading-relaxed">
                    Use clear and concise language in your prompts to improve
                    model comprehension.
                  </li>
                  <li className="leading-relaxed">
                    Implement error handling and fallback strategies for robust
                    application performance.
                  </li>
                  <li className="leading-relaxed">
                    Continuously monitor and analyze prompt performance metrics
                    to identify areas for improvement.
                  </li>
                </ul>
              </Card>
            </div>
          </div>
          <div className="mt-24 lg:w-1/2 lg:pl-8">
            <Card className="sticky top-20 p-6 transition-all duration-300 ease-in-out hover:bg-gray-50">
              <h2 className="font-chivo mb-4 flex items-center text-2xl font-semibold">
                <BookOpenIcon className="mr-3 h-7 w-7 text-gray-600" />
                Table of Contents
              </h2>
              {/* Left column: Table of Contents */}
              <nav className="space-y-2" aria-label="Sidebar">
                {tableOfContents.map(item => (
                  <a
                    key={item.id}
                    href={`#${item.id}`}
                    className={classNames(
                      activeSection === item.id
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
                      'group flex items-center rounded-md px-3 py-2 text-sm font-medium',
                    )}
                  >
                    <span>{item.title}</span>
                  </a>
                ))}
              </nav>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocsPage;
