interface TimelineEvent {
  id: string;
  content: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconBackground: string;
}

export const addEvent = (
  timeline: TimelineEvent[],
  event: TimelineEvent,
): TimelineEvent[] => {
  return [...timeline, event];
};
