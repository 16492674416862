import {
  CheckCircleIcon,
  ChartBarIcon,
  ArrowPathIcon,
  ArrowUpTrayIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

const GettingStartedCard = ({tasks}: {tasks: any[]}) => {
  const completedTasks = tasks.filter(task => task.completed).length;
  const progress = [0, 33, 66, 100][completedTasks];

  const getIconComponent = (iconName: string) => {
    switch (iconName) {
      case 'ChartBarIcon':
        return ChartBarIcon;
      case 'ArrowPathIcon':
        return ArrowPathIcon;
      case 'ArrowUpTrayIcon':
        return ArrowUpTrayIcon;
      default:
        return null;
    }
  };

  return (
    <div className="mx-3 mb-3 max-w-[200px] border border-gray-200 bg-white p-3 text-sm shadow-sm">
      <div className="mb-2 flex items-center justify-between">
        <h3 className="font-chivo text-base text-sm font-semibold text-gray-800">
          Getting Started
        </h3>
        <div className="rounded bg-gray-100 px-1.5 py-0.5 text-[8px] text-gray-700">
          {progress}%
        </div>
      </div>
      <ul className="space-y-1">
        {tasks.map(task => {
          const IconComponent = getIconComponent(task.icon);
          return (
            <li key={task.id} className="flex items-center">
              {task.completed ? (
                <CheckCircleIcon className="mr-1.5 h-4 w-4 text-green-500" />
              ) : IconComponent ? (
                <IconComponent className="mr-1.5 h-4 w-4 text-gray-300" />
              ) : (
                <span className="mr-1.5 h-4 w-4" />
              )}
              <span
                className={`text-[10px] ${task.completed ? 'text-gray-400 line-through' : 'text-gray-700'}`}
              >
                {task.text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GettingStartedCard;
