import {to100pointScale} from './string';

export type ScoreDescription = {
  scores: string;
  description: string;
};

export function parseScoreDescription(input: string): ScoreDescription[] {
  const result: ScoreDescription[] = [];

  const regex = /Score (\d\.\d)-(\d\.\d): ([^.]+)\./g;
  let match;

  while ((match = regex.exec(input)) !== null) {
    const [, startScore, endScore, description] = match;
    const startScore100 = to100pointScale(startScore);
    const endScore100 = to100pointScale(endScore);
    if (startScore100 !== null && endScore100 !== null) {
      result.push({
        scores: `${startScore100}-${endScore100}`,
        description: description.trim(),
      });
    }
  }

  return result;
}
