import {NextUIProvider} from '@nextui-org/react';
import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App.tsx';
import './index.css';
import {sentryInit} from './sentry';

sentryInit();

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <NextUIProvider>
      <App />
    </NextUIProvider>
  </React.StrictMode>,
);
