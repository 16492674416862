import {Link} from '@nextui-org/react';
import React from 'react';

export default function NotFound() {
  return (
    <div className="relative w-full pt-[4.5rem]">
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="-mt-[4.5rem] rounded-md bg-white p-6 shadow-md">
          <div className="mb-2 text-left text-sm">
            <Link
              href="/"
              className="mb-2 text-sm text-black text-blue-600 hover:underline"
            >
              Home
            </Link>
            <h1 className="font-chivo mb-4 text-xl">404: Page Not Found</h1>
            <p className="text-gray-600">
              The page you're looking for doesn't exist or has been moved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
