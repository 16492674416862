import {InformationCircleIcon} from '@heroicons/react/24/outline';
import {Tooltip} from '@nextui-org/react';
import React from 'react';

import {ScoringTooltip} from './ScoringTooltip';

interface TaskInfo {
  taskName: string;
  criteria: Array<{
    criterion_name: string;
    assessment: string;
    scoring_guidelines: string;
  }>;
}

export default function CriteriaCard({taskInfo}: {taskInfo: TaskInfo}) {
  const {criteria} = taskInfo;
  return (
    <div className="pb-8">
      <div className="bg-white px-5 py-6 shadow">
        <div className="mb-4 mr-12 flex flex-row content-center items-center justify-between">
          <div className="flex flex-row items-center justify-between">
            <h2 className="font-chivo text-m text-left">Criteria</h2>
            <Tooltip
              placement="bottom-start"
              content={
                <div className="flex flex-row flex-nowrap items-center justify-between">
                  <img
                    src="/public/magic-wand.png"
                    alt="magic wand"
                    className="h-10 w-10"
                  />
                  <p className="font-chivo m-2">
                    This criteria is automatically generated using AI based on
                    the prompt and data
                    <br />
                    provided.
                  </p>
                </div>
              }
            >
              <InformationCircleIcon className="ml-3 h-5 w-5 text-[#485ED5]" />
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {criteria.map((criterion, index) => (
            <div key={index} className="mb-3 flex flex-col items-start">
              <ScoringTooltip criteria={criterion}>
                <h3 className="mb-1.5 font-bold underline">
                  {criterion.criterion_name}
                </h3>
              </ScoringTooltip>
              <p className="text-left text-[#6B7280]">{criterion.assessment}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
