import axios from 'axios';

import useStore from './store';
import env from '../env';

const prodApi = env.PROD_API;
const devApi = env.DEV_API;

const activeApi = env.MODE === 'production' ? prodApi : devApi;

const axiosInstance = axios.create({
  baseURL: activeApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const accessToken = useStore.getState().session?.access_token;

    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  },
);

export default axiosInstance;

export function getHeaders(): Record<string, string> {
  const {session} = useStore.getState();
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (session?.access_token) {
    headers['Authorization'] = `Bearer ${session.access_token}`;
  }

  return headers;
}
